import React from 'react';
import PropTypes from 'prop-types';
import Seo from './SEO';

function PostSeo({ markdownRemark, path }) {
  const { frontmatter, excerpt } = markdownRemark;
  const {
    title, featuredImage, author, datePublished, dateModified,
  } = frontmatter;

  const image = featuredImage.childImageSharp
    .gatsbyImageData.images.fallback.src;
  const siteURL = 'https://www.cicerone.blog';
  const imageURL = `${siteURL}${image}`;
  const postURL = `${siteURL}${path}`;

  const structuredDataSnippet = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: siteURL,
      name: title,
      alternateName: title,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [{
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': postURL,
          name: title,
          image: 'https://www.cicerone.blog/logo.png',
        },
      }],
    }, {
      '@context': 'http://schema.org',
      '@type': 'BlogPosting',
      url: postURL,
      name: title,
      alternateName: title,
      headline: title,
      datePublished,
      dateModified,
      author: {
        '@type': 'Person',
        name: author,
        url: 'https://www.instagram.com/sk8guerra',
      },
      image: {
        '@type': 'ImageObject', url: imageURL,
      },
      description: excerpt,
    }];

  return (
    <Seo dataSnippet={structuredDataSnippet} postSeo image={imageURL} />
  );
}

PostSeo.propTypes = {
  markdownRemark: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default PostSeo;
