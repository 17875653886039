import React from 'react';
import PropTypes from 'prop-types';
import Article from './Article';

function PostArticle({ markdownRemark }) {
  return <Article markdownRemark={markdownRemark} />;
}

PostArticle.propTypes = {
  markdownRemark: PropTypes.object.isRequired,
};

export default PostArticle;
