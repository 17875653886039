import React from 'react';
import PropTypes from 'prop-types';

function ArticleDescription({ description }) {
  return <div className="post-description">{description}</div>;
}

ArticleDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default ArticleDescription;
