export const ESPANIOL_ORIGINAL = "español";
export const ORTOGRAFIA_ORIGINAL = "ortografía";
export const GRAMATICA_ORIGINAL = "gramática";
export const ALEMAN_ORIGINAL = "alemán";
export const SILABA_ORIGINAL = "sílaba";
export const ANALISIS_SINTACTICO_ORIGINAL = "análisis sintáctico";
export const INGLES_ORIGINAL = "inglés";
export const TIEMPO_VERBAL_ORIGINAL = "tiempo verbal";
export const GENERO_ORIGINAL = "género";

export const ESPANIOL_FAKE = "espaniol";
export const ORTOGRAFIA_FAKE = "ortografia";
export const GRAMATICA_FAKE = "gramatica";
export const ALEMAN_FAKE = "aleman";
export const SILABA_FAKE = "silaba";
export const ANALISIS_SINTACTICO_FAKE = "analisis-sintactico";
export const INGLES_FAKE = "ingles";
export const TIEMPO_VERBAL_FAKE = "tiempo-verbal";
export const GENERO_FAKE = "genero";
