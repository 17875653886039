import React from 'react';
import PropTypes from 'prop-types';
import { areEqual } from 'werkstatt';

function ArticleAuthors({ authors }) {
  const styledAuthors = authors.map((author, index, row) => (
    <div>
      <span>{author}</span>
      {areEqual(index + 1, row.length) ? '.' : ','}
    </div>
  ));

  return (
    <div className="post-author">
      Escrito por:
      {' '}
      {styledAuthors}
    </div>
  );
}

ArticleAuthors.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ArticleAuthors;
