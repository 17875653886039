import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Content from './Content';
import Card from './Card';

function RelatedPosts({ relatedPosts }) {
  return (
    <StaticQuery
      query={graphql`
      query readRelatedArticles {
        all: allMarkdownRemark(
          sort: {fields: frontmatter___date, order: DESC}
          filter: {frontmatter: {isPublished: {eq: true}}}
        ) {
          edges {
            node {
              html
              frontmatter {
                path
                authors
                date(formatString: "DD [de] MMMM, YYYY", locale: "es")
                tags
                title
                description
                homeImage {
                  childImageSharp {
                    gatsbyImageData(width: 275, quality: 80, layout: CONSTRAINED)
                  }
                }
              }
              excerpt
            }
          }
        }
      }

    `}
      render={(data) => {
        const postsToShow = [];

        if (!relatedPosts) {
          return null;
        }

        relatedPosts.forEach((slug) => {
          const foundArticle = data.all.edges.find(({ node }) => node.frontmatter.path === slug);
          postsToShow.push(foundArticle);
        });

        const posts = postsToShow.map(({ node, index }) => {
          const { excerpt, frontmatter } = node;
          const {
            title, date, tags, path, homeImage,
          } = frontmatter;
          return (
            <Card
              key={index}
              title={title}
              date={date}
              tags={tags}
              path={path}
              summary={excerpt}
              img={homeImage.childImageSharp.gatsbyImageData}
            />
          );
        });

        return (
          <>
            <br />
            <h1 className="related-posts-title">También te podría interesar...</h1>
            <Content isHome>
              <div className="posts">
                {posts}
              </div>
            </Content>
          </>
        );
      }}
    />
  );
}

RelatedPosts.propTypes = {
  relatedPosts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RelatedPosts;
