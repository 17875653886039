import React from 'react';
import PropTypes from "prop-types";

const CenteredContent = ({ children }) => (
  <div className="flex flex-center">
    {children}
  </div>
);;

CenteredContent.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CenteredContent;
