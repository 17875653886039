import React from 'react';
import Ad from './Ad';
import CenteredContent from './CenteredContent';

function PostAds() {
  return (
    <CenteredContent>
      <Ad />
    </CenteredContent>
  );
}

export default PostAds;
