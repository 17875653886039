import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';

function PostHeader({ markdownRemark }) {
  const { frontmatter } = markdownRemark;
  const {
    title, date, readTime, place, featuredImage,
  } = frontmatter;

  const image = featuredImage.childImageSharp.gatsbyImageData;

  return (
    <Header
      isPost
      title={title}
      postInfo={{ date, readTime, place }}
      img={image}
    />
  );
}

PostHeader.propTypes = {
  markdownRemark: PropTypes.object.isRequired,
};

export default PostHeader;
