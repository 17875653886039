import React from "react";
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

const CardActions = ({ path }) => {
  return (
    <div className="card-actions">
      <Link to={path} className="arrow">
        leer más
        <span><FontAwesomeIcon className="card-actions-icon" icon={faArrowRight}/></span>
      </Link>
    </div>
  )
}

export default CardActions;
