import React from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'werkstatt';

function PostUpdateInfo({ frontmatter }) {
  const { updatedOn } = frontmatter;

  if (isNull(updatedOn)) return null;

  return (
    <>
      <br />
      <p className="last-time-updated">{`Actualizado última vez: ${updatedOn}`}</p>
      <hr />
    </>
  );
}

PostUpdateInfo.propTypes = {
  frontmatter: PropTypes.object.isRequired,
};

export default PostUpdateInfo;
