import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import scrollTo from 'gatsby-plugin-smoothscroll';

function ArticleActions() {
  return (
    <div className="container flex flex-space-between">
      <Link to="/" className="arrow">
        <span><FontAwesomeIcon icon={faArrowLeft} /></span>
        regresar
      </Link>

      <span
        role="button"
        tabIndex={0}
        className="arrow"
        onClick={() => scrollTo('#top')}
        onKeyDown={() => scrollTo('#top')}
      >
        <FontAwesomeIcon icon={faArrowUp} />
        inicio
      </span>

    </div>
  );
}

ArticleActions.propTypes = {};

export default ArticleActions;
