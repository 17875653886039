import React from 'react';
import PropTypes from 'prop-types';
import PostUpdateInfo from './PostUpdateInfo';
import RelatedPosts from './RelatedPosts';
import Content from './Content';

function PostFooter({ markdownRemark }) {
  const { frontmatter } = markdownRemark;
  const { relatedPosts } = frontmatter;
  return (
    <Content isHome>
      <PostUpdateInfo frontmatter={frontmatter} />
      <RelatedPosts relatedPosts={relatedPosts} />
    </Content>
  );
}

PostFooter.propTypes = {
  markdownRemark: PropTypes.object.isRequired,
};

export default PostFooter;
