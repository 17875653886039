import React from 'react';
import PropTypes from 'prop-types';

function ArticleContent({ html }) {
  return <div className="post-content" dangerouslySetInnerHTML={{ __html: html }} />;
}

ArticleContent.propTypes = {
  html: PropTypes.string.isRequired,
};

export default ArticleContent;
