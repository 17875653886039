import React from 'react';
import { Link } from "gatsby";

const CardHeader = ({ path, title }) => {
  return (
    <Link to={path} className="arrow">
      <h3 className="card__header">{title}</h3>
    </Link>
  )
}

export default CardHeader;
