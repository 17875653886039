import React from 'react';
import PropTypes from 'prop-types';
import PostArticle from './PostArticle';
import Content from './Content';

function PostBody({
  markdownRemark, reactionData, path, refetch,
}) {
  return (
    <Content isPost reactionData={reactionData} postPath={path} refetch={refetch}>
      <PostArticle markdownRemark={markdownRemark} />
    </Content>
  );
}

PostBody.propTypes = {
  path: PropTypes.string.isRequired,
  reactionData: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  markdownRemark: PropTypes.object.isRequired,
};

export default PostBody;
