import React from 'react';
import { Link } from "gatsby";
import {
  ESPANIOL_FAKE,
  ESPANIOL_ORIGINAL,
  ORTOGRAFIA_FAKE,
  ORTOGRAFIA_ORIGINAL,
  GRAMATICA_FAKE,
  GRAMATICA_ORIGINAL,
  ALEMAN_FAKE,
  ALEMAN_ORIGINAL,
  SILABA_FAKE,
  SILABA_ORIGINAL,
  ANALISIS_SINTACTICO_FAKE,
  ANALISIS_SINTACTICO_ORIGINAL,
  INGLES_FAKE,
  INGLES_ORIGINAL,
  TIEMPO_VERBAL_ORIGINAL,
  TIEMPO_VERBAL_FAKE,
  GENERO_FAKE,
  GENERO_ORIGINAL
} from "../constants"

const Tag = ({ title }) => {
  const getTagContent = title => {
    switch (title) {
      case ESPANIOL_FAKE: return ESPANIOL_ORIGINAL;
      case ORTOGRAFIA_FAKE: return ORTOGRAFIA_ORIGINAL;
      case GRAMATICA_FAKE: return GRAMATICA_ORIGINAL;
      case ALEMAN_FAKE: return ALEMAN_ORIGINAL;
      case SILABA_FAKE: return SILABA_ORIGINAL;
      case ANALISIS_SINTACTICO_FAKE: return ANALISIS_SINTACTICO_ORIGINAL;
      case INGLES_FAKE: return INGLES_ORIGINAL;
      case TIEMPO_VERBAL_FAKE: return TIEMPO_VERBAL_ORIGINAL;
      case GENERO_FAKE: return `${GENERO_ORIGINAL}`
      default: return title;
    }
  }

  return (
    <Link className={`tag`} key={title} to={`/tags/${title}`}>
      {getTagContent(title)}
    </Link>
  );
}

export default Tag;
