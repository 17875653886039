import React from 'react';
import wooflangLogo from '../assets/images/wooflang_logo.png';

function Ad() {
  return (
    <a
      className="ad"
      target="_blank"
      href="https://wooflang.com/"
      rel="noreferrer"
    >
      <div className="ad__heading__container">
        <h1>¿Quieres avance notables en el idioma que aprendes?</h1>
      </div>
      <div className="ad__image__container">
        <img alt="wooflang-logo" src={wooflangLogo} />
      </div>
      <div className="ad__text__container">
        <p>
          Prueba Wooflang y crea tus propios ejercicios con el contenido que más
          disfrutes y practica: lectura, escritura, audición y mucho más.
        </p>
      </div>
    </a>
  );
}

export default Ad;
