import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { isNull } from 'werkstatt';
import Layout from '../../components/Layout';
import PostProgressBar from '../../components/PostProgressBar';
import PostHeader from '../../components/PostHeader';
import PostSeo from '../../components/PostSeo';
import PostBody from '../../components/PostBody';
import PostFooter from '../../components/PostFooter';
import PostAds from '../../components/PostAds';

const GET_REACTIONS = gql`
    query readReaction($postPath: String!) {
        readReaction(postPath: $postPath) {
            data {
                _id
                postPath
                reaction
                symbol
            }
        }
    }
`;

function Post({ path, data: { markdownRemark } }) {
  const { data, refetch } = useQuery(GET_REACTIONS, { variables: { postPath: path } });

  if (isNull(markdownRemark)) return null;

  return (
    <Layout>
      <main>
        <PostProgressBar />
        <PostSeo markdownRemark={markdownRemark} path={path} />
        <PostHeader markdownRemark={markdownRemark} />
        <PostBody
          markdownRemark={markdownRemark}
          reactionData={data}
          path={path}
          refetch={refetch}
        />
        <PostAds />
        <PostFooter markdownRemark={markdownRemark} />
      </main>
    </Layout>
  );
}

Post.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default Post;

export const postQuery = graphql`query BlogQuery($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path}}) {
        id
        html
        frontmatter {
            authors
            datePublished: date
            dateModified: updatedOn
            date(formatString: "DD [de] MMMM, YYYY", locale: "es")
            updatedOn(formatString: "DD [de] MMMM, YYYY", locale: "es")
            description
            title
            tags
            readTime
            featuredImage {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            place
            relatedPosts
        }
        excerpt
    }
}
`;
