import React from 'react';

const CardContainer = ({ children }) => {
  return (
    <div className="card">
      {children}
    </div>
  )
}

export default CardContainer;
