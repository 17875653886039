import React from 'react';

import CardContainer from "./CardContainer";
import CardContent from "./CardContent"
import CardMedia from "./CardMedia";
import CardHeader from "./CardHeader";
import CardSupportingText from "./CardSupportingText";
import CardActions from "./CardActions";
import CardTags from "./CardTags"

const Card = ({ title, date, path, summary, tags, img }) => {
  return (
    <CardContainer>
      <CardMedia image={img} alt={`${title} cover`} />
      <CardContent>
        <time className="card-date">{date}</time>
        <CardHeader path={path} title={title} />
        <CardSupportingText text={summary} />
        <CardTags tags={tags} />
        <CardActions path={path} />
      </CardContent>
    </CardContainer>
  );
};

export default Card;
