import React from 'react';
import PropTypes from 'prop-types';
import ArticleDescription from './ArticleDescription';
import ArticleContent from './ArticleContent';
import ArticleActions from './ArticleActions';
import ArticleTags from './ArticleTags';
import ArticleAuthors from './ArticleAuthors';

function Article({ markdownRemark }) {
  const { frontmatter, html } = markdownRemark;
  const { description, tags, authors } = frontmatter;

  return (
    <article>
      <ArticleAuthors authors={authors} />
      <ArticleDescription description={description} />
      <ArticleContent html={html} />
      <ArticleActions />
      <ArticleTags tags={tags} />
      <p style={{
        marginTop: 50,
        textAlign: 'center',
        backgroundColor: '#ECECEC',
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 5,
      }}
      >
        ¿Quieres contribuir con algún artículo? Mándalo a mi correo:
        <a
          href="mailto:jorgeguerra1098@gmail.com?subject=New%20Article"
        >
          {' '}
          jorgeguerra1098@gmail.com
        </a>
      </p>
    </article>
  );
}

Article.propTypes = {
  markdownRemark: PropTypes.shape({
    html: PropTypes.string,
    frontmatter: PropTypes.shape({
      description: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      authors: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

export default Article;
