import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"

const CardMedia = ({ alt, image }) => {
  return (
    <GatsbyImage image={image} className="card__img" alt={alt} />
  )
}

export default CardMedia;
