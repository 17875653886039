import React from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';

function ArticleTags({ tags }) {
  return (
    <div className="post-tags">
      {tags.map((tag) => <Tag key={`${tag}`} title={tag} />)}
    </div>
  );
}

ArticleTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ArticleTags;
