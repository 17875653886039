import React from "react";
import Tag from "./Tag"

const CardTags = ({ tags }) => {
  return (
    <div className="card-tags">
      {tags.map((tag, index) => {
        return <Tag key={`${index}${tag}`} title={tag}/>
      })}
    </div>
  );
};

export default CardTags;
